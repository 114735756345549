<template>
    <div class="main orte-index">
        <div class="main-sidebar">
            <div class="main-sidebar__head">
                <h4 class="title">Orte</h4>
                <p>Anbietende, Institutionen, Vereine und weitere Orte</p>
            </div>

            <div v-if="tempShowHide" class="main-sidebar__filter">
                <div v-if="!expandFilter && !$isMobileScreen">
                    <keyword-search class="mb20" :focusMe="true" :initialValue="dataSet.query.meilisearch"
                        @searchWordChanged="searchWordChanged" />

                    <MainCategorySelect class="mb15" name="Kategorie" :name="mainBasicFilter.label"
                        :field-type-id="mainBasicFilter.field_type_id" :values="mainBasicFilter.options"
                        :selectedFilters="selectedFilters" @on-select="filterChanged" />

                    <WBSelectField v-if="basicFilters !== null" v-for="(item, index) in basicFilters"
                        :key="item.field_type_id" class="mb15" :name="item.label" :field-type-id="item.field_type_id"
                        :values="item.options" :selectedFilters="selectedFilters" :isMultiple="true"
                        @on-select="filterChanged" />

                    <button class="btn btn-link text-primary mb20" @click="resetAllFilters">Filter zurücksetzen</button>
                    <button class="btn btn-link float-right text-primary mb20" @click="filterModal = true">Mehr
                        Filter</button>

                    <div class="clearfix"></div>
                </div>

                <button @click="expandFilter = false" v-if="expandFilter" class="btn btn-expand btn-link"
                    ref="searchExpand">Suche und Filter ausklappen</button>
                <button @click="expandFilter = true" v-else class="btn btn-expand btn-link">Suche und Filter
                    einklappen</button>
            </div>

            <div tabindex="-1" class="main-sidebar__content" v-if="dataSet.data !== null && dataSet.data.length > 0">
                <!-- data will be here -->
                <h6>{{ dataSet.total }} Ergebnisse</h6>
                <orte-card v-for="(item, index) in dataSet.data" :key="index" target="map" :item="item"
                    @highlight="highlightThisContent" :id="'vertical-content-' + item.id" />
            </div>

            <div v-else-if="!searching" class="search-no-results">
                <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                <h5 class="mt20">Keine Ergebnisse gefunden</h5>
            </div>

        </div>

        <div class="main-content" id="main-scroll-container">
            <TitleBar v-if="contentType !== null && $isMobileScreen" karteLink="/orte" :filters="allFilters"
                :selectedFilters="selectedFilters" listeLink="/orte?show=liste" :showMapOrList="showMap" :isFixed="true"
                :showButtons="true" :contentCount="tempContentCount"
                :initialKeywordSearchValue="dataSet.query.meilisearch" @contentFilteringFinished="filteringFinished"
                @resetAllFilters="resetAllFilters" @getNumberOfResults="getNumberOfResults"
                @searchWordChanged="searchWordChanged" />

            <div v-if="showMap" class="karte">

                <multiple-map ref="theMap" class="the-karte" :contents="dataSet.data"
                    @highlight="highlightThisContent" />

                <div class="karte-cards" v-if="dataSet.data !== null && dataSet.data.length > 0 && $isMobileScreen">
                    <div class="container-fluid">
                        <div class="row mobile--scrolling horizontal-scroll">
                            <map-card v-for="(item, index) in dataSet.data" :key="index" :item="item"
                                @highlight="highlightThisContent" :id="'horizontal-content-' + item.id" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="list-section section">
                <div class="container-fluid">
                    <div class="row" v-if="dataSet.data !== null && dataSet.data.length > 0">
                        <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in dataSet.data" :key="index">
                            <orte-card :item="item" />
                        </div>
                    </div>

                    <div v-else-if="!searching" class="search-no-results">
                        <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                        <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                    </div>

                </div>
            </div>

        </div>

        <main-category-select-modal @filterChanged="filterAndFocusChanged" :filterId="parseInt(353)"
            v-if="showMainCategorySelectModal && mainBasicFilter !== null" />

        <StructuredMoreFilters :showModal="filterModal" @on-close="updateOnClose" :contentCount="tempContentCount"
            :selectedFilters="selectedFilters" @getNumberOfResults="getNumberOfResults"
            :structuredFilters="structuredFilters" v-if="filterModal && structuredFilters !== null && !isMobileView"
            @filteringFinished="filteringFinished" />

    </div>
</template>

<script>
import Resource from '@/api/resource';
const contentTypeResource = new Resource('contenttypes');
import { getSublocalities } from '@/api/system';
import { getContentsOfType } from '@/api/content';
import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, getStructuredFilters, getBasicFilters, argsFilterMapToJSMap, isset } from '@/utils/helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import VueScrollTo from 'vue-scrollto';

export default {
    name: "Orte",
    mixins: [screenSizeMixin],
    components: {
        MapCard: () => import('./components/OrteMapCard.vue'),
        TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
        OrteCard: () => import('@/components/cards/Ort.vue'),
        MultipleMap: () => import('@/components/map/multiple.vue'),
        WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
        MainCategorySelect: () => import('@/components/controls/MainCategorySelect.vue'),
        MainCategorySelectModal: () => import('@/components/modals/MainCategorySelectModal.vue'),
        StructuredMoreFilters: () => import('@/components/modals/StructuredMoreFilters/StructuredMoreFilters.vue'),
        KeywordSearch: () => import('@/components/controls/KeywordSearch'),
    },
    data() {
        return {
            tempShowHide: true,
            showMainCategorySelectModal: false,
            currentContentTypeId: this.$institutionId,
            tempContentCount: 0,
            searching: true,
            contentType: null,
            sublocalities: null,
            isMobileView: false,
            dataSet: {
                total: 0,
                data: [],
                meta: null,
                query: {
                    page: 1,
                    type: "teaser",
                    limit: 500,
                    meilisearch: '',
                    filters: null,
                    selectedAppId: this.$appId,
                    selectedContentTypeId: this.currentContentTypeId,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
            selectedCategory: null,
            selectedFilters: new Map(),
            expandFilter: true,
            filterModal: false,
            scrollVerticalOptions: {
                container: '.main-sidebar__content',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: false,
                y: true,
            },
            scrollHorizontalOptions: {
                container: '.horizontal-scroll',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: true,
                y: false,
            },
        }
    },
    created() {
        this.getInstitutionSettings();
        var filters = argsFilterMapToJSMap(this.uriFilters);
        if (isset(filters)) {
            this.filteringFinished(filters);
            this.showMainCategorySelectModal = false;
        }
        else {
            this.showMainCategorySelectModal = true;
        }
    },
    watch: {
        $screenWidth(newWidth) {
            if (newWidth <= 991) {
                this.isMobileView = true;
            }
            else {
                this.isMobileView = false;
            }
        },
        isMobileView(newValue, oldValue) {
            /*resetting the filters if the view changes from mobile to desktop*/
            if (newValue !== oldValue) {
                this.selectedFilters = new Map();
            }
        }
    },
    computed: {
        uriFilters() {
            return this.$route.query.filters;
        },
        showMap() {
            if (this.$isDesktopScreen) {
                return true;
            }

            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'show')) {
                return false;
            }
            return true;
        },
        structuredFilters() {
            return getStructuredFilters(this.contentType);
        },
        basicFilters() {
            var filters = getBasicFilters(this.structuredFilters);

            if (this.sublocalityFilter !== null) {
                filters.push(this.sublocalityFilter);
            }
            return filters;
        },
        allFilters() {
            return this.structuredFilters.concat(this.sublocalityFilter);
        },
        sublocalityFilter() {
            if (this.sublocalities !== null) {
                var bla = {};
                bla.field_type_id = "location";
                bla.label = "Stadtteile";
                bla.options = []
                for (var i = 0; i < this.sublocalities.length; i++) {
                    bla.options.push({ label: this.sublocalities[i].sublocality, value: this.sublocalities[i].sublocality });
                }
                return bla;
            }
            return null;
        },
        mainBasicFilter() {
            if (this.basicFilters !== null && this.basicFilters.length > 0) {
                var result = this.basicFilters.splice(0, 1);
                return result[0];
            }
            return null;
        },
    },
    methods: {
        showLoader,
        hideLoader,
        getSublocalities,
        createSelectOptions,
        getAllowedValuesFromFieldType,
        filterAndFocusChanged(fieldTypeId, values) {
            this.filterChanged(fieldTypeId, values);
            this.$refs.searchExpand.focus();
        },
        filterChanged(fieldTypeId, values) {
            this.selectedFilters.set(fieldTypeId, values);
            this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
            this.getContents();
        },
        getInstitutionSettings(val) {
            this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
            this.$scrollTo();
            this.getContentType();
            this.getTheSublocalities();
        },
        searchWordChanged(word) {
            this.dataSet.query.meilisearch = word;
            this.getContents();
        },
        getNumberOfResults(map) {
            /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
            var tempFilters = new Map([...this.selectedFilters, ...map]);
            /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
            this.getNumberOfContentsMatchingTheFilter(tempFilters);
        },
        filteringFinished(map) {
            /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
            this.selectedFilters = map;
            this.filterModal = false;
            this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
            this.getContents();
        },
        resetAllFilters() {
            this.tempShowHide = false;
            if (this.mainBasicFilter !== null && this.mainBasicFilter.hasOwnProperty('field_type_id')) {
                var res = this.selectedFilters.get(this.mainBasicFilter.field_type_id);
                this.selectedFilters = new Map();
                if (res !== null && res !== undefined) {
                    this.selectedFilters.set(this.mainBasicFilter.field_type_id, res);
                }
            }
            else {
                this.selectedFilters = new Map();
            }
            this.filteringFinished(this.selectedFilters);
        },
        updateOnClose() {
            this.filterModal = false;
        },
        getContentType() {
            this.contentType = null;
            var id = this.currentContentTypeId;
            this.loader = this.showLoader(this.loader);
            /*overwrite the existing filters*/
            this.selectedFilters = new Map();
            contentTypeResource.get(id).then(response => {
                this.contentType = response.data;
            })
                .finally(() => {
                    this.loader = this.hideLoader(this.loader);
                });
        },
        async getTheSublocalities() {
            var id = this.currentContentTypeId;
            getSublocalities(this.$city, null, id).then(response => {
                this.sublocalities = response;
            });
        },
        getContents() {
            this.searching = true;
            history.pushState({}, null, this.$route.path + '?filters=' + encodeURIComponent(this.dataSet.query.filters));
            this.dataSet.query.type = "view";
            var contentLoader = this.showLoader(contentLoader);
            const { limit, page } = this.dataSet.query;
            getContentsOfType(this.currentContentTypeId, this.dataSet.query).then(response => {
                const { data, meta } = response;
                this.dataSet.data = data;
                this.dataSet.data.forEach((element, index) => {
                    element['index'] = (page - 1) * limit + index + 1;
                });
                this.dataSet.meta = meta;
                this.dataSet.total = meta.total;
                this.tempContentCount = meta.total;
            })
                .finally(() => {
                    contentLoader = this.hideLoader(contentLoader);
                    document.getElementById('main-scroll-container').scroll({ top: 0, behavior: 'smooth' });
                    this.tempShowHide = true;
                    this.searching = false;

                });
        },
        getNumberOfContentsMatchingTheFilter(tempFilters) {
            this.dataSet.query.type = "count";
            this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
            var contentLoader = this.showLoader(contentLoader);
            getContentsOfType(this.currentContentTypeId, this.dataSet.query).then(response => {
                this.tempContentCount = response;
            })
                .finally(() => {
                    contentLoader = this.hideLoader(contentLoader);
                });
        },
        highlightThisContent(params) {
            Object.keys(this.dataSet.data).forEach((k) => {
                this.dataSet.data[k].highlight = false;
                if (this.dataSet.data[k].id == params.content.id) {
                    this.dataSet.data[k].highlight = true;

                    if (params.sender != "map") {
                        /*open the popup*/
                        this.$refs.theMap.openPopup(params.content);
                    }
                    else {
                        if (this.$isMobileScreen) {
                            VueScrollTo.scrollTo(('#horizontal-content-' + this.dataSet.data[k].id), '2000', this.scrollHorizontalOptions);
                        }
                        else {
                            VueScrollTo.scrollTo(('#vertical-content-' + this.dataSet.data[k].id), '2000', this.scrollVerticalOptions);
                        }
                    }
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.search-no-results {
    margin-top: 40px;
    width: 100%;
    text-align: center;

    img {
        width: 110px;
    }
}

.karte {
    overflow: hidden;

    .the-karte {
        @media (max-width: 991px) {
            margin-top: 64px;
        }
    }

    .karte-cards {
        width: 100%;
    }
}

.main-sidebar__content {
    overflow-y: auto;
}

.main-sidebar_scroll {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: calc(100vh - 320px);
    scrollbar-width: none;
}

.list-section {
    @media (max-width: 991px) {
        padding: 0px;
    }
}
</style>
